import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { defaultLocale } from '@/helpers/localeHelpers';

Vue.use(VueI18n);

export const i18n: VueI18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  silentTranslationWarn: true
});

export const useI18n = (): VueI18n => {
  return i18n;
};
