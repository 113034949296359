import { getWidgetLanguages, getTranslation, getOperatorTheme, setUserLanguage, updateUserNickname } from '@/api/User';

import { getUserInfo } from '@/api/User';
import { Role } from '@/helpers/roleHelper';
import { LocaleMessages } from 'vue-i18n';
import { i18n } from '@/plugins/i18n';
import mergeWith from 'lodash/mergeWith';
import { getLocalTranslation } from '@/helpers/localeHelpers';
import type { ActionContext } from 'vuex';
import type { LayoutState } from './LayoutState';
import type { UserInfo } from '@/api/schema';
import { isFairspin } from './LayoutState';
import { getStreamSettings, StreamBroadcast } from '@/api/Broadcast';

const localeCustomizer = (value: unknown, srcValue: unknown): string[] | string => {
  if (typeof srcValue === 'string') {
    const str = srcValue.trim();

    if (str.startsWith('[') && str.endsWith(']')) {
      try {
        return JSON.parse(str);
      } catch (e) {
        if (Array.isArray(value)) {
          return value;
        }
        return srcValue;
      }
    }
  }
};

const loadedLanguages = [];

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category LayoutStore
 */
export default {
  async getConfiguration({ commit }: ActionContext<LayoutState, unknown>): Promise<void> {
    return getStreamSettings()
      .then((settings) => commit('setConfiguration', settings))
      .catch(() => commit('setConfiguration', {}));
  },
  async getStreamerInfo({ state, commit }: ActionContext<LayoutState, unknown>): Promise<void> {
    const { walletHash, streamer } = state;

    if (!walletHash || streamer?.channelId) return;

    const commitResult = (res = null) => {
      if (!res?.channelId) return;

      commit('setStreamer', res);
    };

    StreamBroadcast.getInfo().then(commitResult).catch(commitResult);
  },

  /**
   * Action to get user info.
   * @param state Layout state.
   */
  async getUserInfo({ commit }: ActionContext<LayoutState, unknown>): Promise<unknown> {
    return getUserInfo()
      .then((userData: UserInfo): void => {
        commit('setUserInfo', userData);
      })
      .catch((err) => {
        commit('setUserInfo', { role: Role.Anonimus });

        throw Error(err);
      });
  },

  async updateUserNickname({ commit }: ActionContext<LayoutState, any>, nickname: string): Promise<void> {
    return updateUserNickname(nickname).then(() => {
      commit('setUserNickname', nickname);
    });
  },

  /**
   * Action to get widget languages.
   * @param state Layout state.
   */
  async getWidgetLanguages({ commit }: ActionContext<LayoutState, unknown>): Promise<void> {
    getWidgetLanguages().then((data: string[]): void => {
      commit('setPermittedLangs', data);
    });
  },

  /**
   * Action to get translation by locale.
   * @param state Layout state.
   * @param lang
   */
  async loadTranslation({ commit }: ActionContext<LayoutState, unknown>, lang: string): Promise<void> {
    if (loadedLanguages.includes(lang)) return;

    let phraseTranslations: LocaleMessages = {};
    let localTranslation: LocaleMessages = {};
    try {
      [phraseTranslations, localTranslation] = await Promise.allSettled([
        getTranslation(lang),
        getLocalTranslation(lang)
      ]).then((results) =>
        results.map((result) => {
          return result.status === 'fulfilled' ? result?.value : {};
        })
      );
    } catch (err) {
      try {
        localTranslation = await getLocalTranslation(lang);
      } catch (err) {
        localTranslation = {};
      }
    }

    loadedLanguages.push(lang);
    commit('addLoadedLanguage', lang);

    i18n.setLocaleMessage(lang, mergeWith(localTranslation, phraseTranslations, localeCustomizer));
  },

  /**
   * Action to set active language.
   * @param state Layout state.
   * @param lang active language.
   */
  async setActiveLanguage({ dispatch, commit }: ActionContext<LayoutState, unknown>, lang: string): Promise<any> {
    commit('setActiveLanguage', lang);

    if (!loadedLanguages.includes(lang)) {
      await dispatch('loadTranslation', lang);
    }
  },

  /**
   * Action to set user and widget language.
   * @param state Layout state.
   * @param lang active language.
   */
  async changeUserWidgetLanguage({ dispatch }: ActionContext<LayoutState, unknown>, lang: string): Promise<any> {
    setUserLanguage(lang).then(async (): Promise<void> => {
      await dispatch('setActiveLanguage', lang);
    });
  },

  /**
   * Get operator theme.
   * @param state Layout state.
   */
  async getOperatorTheme({ commit }: ActionContext<LayoutState, any>): Promise<void> {
    if (isFairspin) return;

    const data = await getOperatorTheme();
    const colors = data?.colors?.reduce((all, curr) => ({ ...all, [curr.type]: curr.color }), {});

    if (colors) {
      commit('setTheme', { colors, theme: data.value });
    }
  }
};
